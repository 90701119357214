import fetch from 'auth/FetchInterceptor'
const NotificationsService = {}

NotificationsService.getRemindUser = function() {
    return fetch({
        url: '/RemindMe/get-remind-user',
        method: 'get'
    })
}

NotificationsService.getUserSubscriptionRecordNotifications = function() {
    return fetch({
        url: '/Notification/get-user-subscription-record-notifications',
        method: 'get'
    })
}

NotificationsService.getUserSubscriptionRecordNotificationsMonth = function(month) {
    return fetch({
        url: `/Notification/get-user-subscription-record-notifications-month?month=${month}`,
        method: 'get'
    })
}

export default NotificationsService;