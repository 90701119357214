import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import DashboardService from "../../services/DashboardService";

export const getSubscriptionsStatisticDashboardByUserId = createAsyncThunk(
    'application/getSubscriptionsByUserId',
    async (year, { rejectWithValue }) => {
        try {
            const response = await DashboardService.getSubscriptionsStatisticDashboardByUserId(year);
            // console.log(response);
            return response;
        } catch (err) {
            // handle or throw error
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const getUpcomingPaymentsSubscriptionRecord = createAsyncThunk(
    'getUpcomingPayments/subscriptionRecord',
    async (data, { rejectWithValue }) => {
        try {
            const response = await DashboardService.getUpcomingPaymentsSubscriptionRecord();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const getTopExpensesSubscriptionRecord = createAsyncThunk(
    'getTopExpenses/subscriptionRecord',
    async (data, { rejectWithValue }) => {
        try {
            const response = await DashboardService.getTopExpensesSubscriptionRecord();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboards: [],
        upcomingPaymentsSubscriptionRecord: [],
        topExpensesSubscriptionRecord: [],
        transaction: [],
        loading: false,
        error: null,
    },
    reducers: {
        // your other reducers here
    },
    extraReducers: (builder)=> {
        builder
            .addCase(getSubscriptionsStatisticDashboardByUserId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSubscriptionsStatisticDashboardByUserId.fulfilled, (state, action) => {
                state.loading = false;
                state.dashboards = action.payload;
            })

            .addCase(getSubscriptionsStatisticDashboardByUserId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getUpcomingPaymentsSubscriptionRecord.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUpcomingPaymentsSubscriptionRecord.fulfilled, (state, action) => {
                state.loading = false;
                state.upcomingPaymentsSubscriptionRecord = action.payload;
            })

            .addCase(getUpcomingPaymentsSubscriptionRecord.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(getTopExpensesSubscriptionRecord.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTopExpensesSubscriptionRecord.fulfilled, (state, action) => {
                state.loading = false;
                state.topExpensesSubscriptionRecord = action.payload;
            })

            .addCase(getTopExpensesSubscriptionRecord.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
})

export default DashboardSlice.reducer;