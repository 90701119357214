import fetch from "../auth/FetchInterceptor";
const GroupService = {}

GroupService.getAllGroups = function () {
    return fetch({
        url: '/Group/get-all-groups',
        method: 'get'
    })
}

GroupService.deleteAllExceptGroups = function (data) {
    return fetch({
        url: '/Group/delete-all-except-groups',
        method: 'delete',
        data: data
    })
}


export default GroupService;