import fetch from "../auth/FetchInterceptor";

const SubscriptionRecordService = {}

SubscriptionRecordService.postSubscriptionRecord = function (data) {
    return fetch({
        url: `/SubscriptionRecord/add-subscription-record`,
        method: 'post',
        data: data
    })
}

SubscriptionRecordService.getAllSubscriptionsRecords = function ({subscriptionId}) {
    return fetch({
        url: `/SubscriptionRecord/get-all-subscription-records-by-subscription=id?subscriptionId=${subscriptionId}`,
        method: 'get',
    })
}

SubscriptionRecordService.deleteSubscriptionRecordById = function (subscriptionRecordId) {
    return fetch({
        url: `/SubscriptionRecord/delete-subscription-record-by-id?subscriptionRecordId=${subscriptionRecordId}`,
        method: 'delete',
    })
}

SubscriptionRecordService.getAllSubscriptionRecordStatistic = function ({subscriptionId}) {
    return fetch({
        url: `/SubscriptionRecord/get-all-subscription-record-statistic?subscriptionId=${subscriptionId}`,
        method: 'get',
    })
}

SubscriptionRecordService.getAllSubscriptionRecordTransaction = function ({subscriptionId}) {
    return fetch({
        url: `/Transaction/get-transactions-by-subscriptions-records?subscriptionId=${subscriptionId}`,
        method: 'get',
    })
}

SubscriptionRecordService.editSubscriptionRecord = function (data) {
    return fetch({
        url: `/SubscriptionRecord/edit-subscription-record`,
        method: 'put',
        data: data
    })
}


export default SubscriptionRecordService;