import fetch from 'auth/FetchInterceptor';

const StatisticService = {}

StatisticService.monthlyTransactionSummary = function (year) {
    return fetch({
        url: `/Transaction/get-monthly-transaction-summary?year=${year}`,
        method: 'get'
    })
}


StatisticService.dailyTransactionSummary = function (month) {
    return fetch ({
        url: `/Transaction/get-daily-transaction-summary?year=2024&month=${month}`,
        method: 'get'
    })
}

StatisticService.getTransactionsTotalSpend = function () {
    return fetch ({
        url: '/Transaction/get-transactions-statistic-dashboard-by-user-id',
        method: 'get'
    })
}


StatisticService.monthlySubscriptionSummary = function (year) {
    return fetch ({
        url: `/Subscription/get-monthly-subscription-summary?year=${year}`,
        method: 'get'
    })
}

StatisticService.dailySubscriptionSummary = function (month) {
    return fetch ({
        url: `/Subscription/get-daily-subscription-summary?year=2024&month=${month}`,
        method: 'get'
    })
}


StatisticService.getSubscriptionsEstimatedCostAndApplications = function () {
    return fetch ({
        url: '/Subscription/get-subscriptions-statistic-dashboard-by-user-id',
        method: 'get'
    })
}

StatisticService.getStatisticSubscriptionsByYear = function (year) {
    return fetch ({
        url: `/Subscription/get-statistic-subscriptions?year=${year}`,
        method: 'get'
    })
}

StatisticService.getStatisticYearByUserId = function (year) {
    return fetch ({
        url: `/Subscription/get-user-year-subscriptions-stats?year=${year}`,
        method: 'get'
    })
}

StatisticService.getMonthlySubscriptionsStatisticByUserId = function (month) {
    return fetch ({
        url: `/Subscription/get-user-monthly-subscriptions-stats?year=2024&month=${month}`,
        method: 'get'
    })
}

StatisticService.getStatisticSubscriptionsByMonth = function (month) {
    return fetch ({
        url: `/Subscription/get-subscriptions-statistic-month-by-user-id?year=2024&month=${month}`,
        method: 'get'
    })
}

// new yearly/monthly statistics for subscription records

StatisticService.getYearlySubscriptionRecordStatistics = function (year) {
    return fetch ({
        url: `/SubscriptionRecord/yearly-subscription-record-statistics?year=${year}`,
        method: 'get'
    })
}

StatisticService.getMonthlySubscriptionRecordStatistics = function (month) {
    return fetch ({
        url: `/SubscriptionRecord/monthly-subscription-record-statistics?year=2024&month=${month}`,
        method: 'get'
    })
}

export default StatisticService;