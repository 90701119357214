import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/Account/login',
		method: 'post',
		data: data
	})
}

AuthService.confirmEmail = function (token) {
	return fetch({
		url: '/Account/confirm-email',
		method: 'post',
		data: { token }
	});
}

AuthService.register = function (data) {
	return fetch({
		url: '/Account/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function (data) {
	return fetch({
		url: '/Account/logout',
		method: 'post',
		data: {refreshToken: data}
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

AuthService.resetPassword = function (data) {
	return fetch({
		url: '/Account/reset-password',
		method: 'post',
		data: data
	})
}

AuthService.forgetPassword = function (data) {
	return fetch({
		url: '/Account/forgetPassword',
		method: 'put',
		data: data
	})
}

AuthService.googleAuthenticate = function (data) {
	return fetch({
		url: '/Account/google-authenticate',
		method: 'post',
		data: data
	})
}
export default AuthService;