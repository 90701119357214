import fetch from 'auth/FetchInterceptor';

const GetAllService = {
    getServices(pageIndex = 1, pageSize = 200, searchString = '') {
        return fetch({
            url: `/Service/get-by-page?pageIndex=${pageIndex}&pageSize=${pageSize}&searchString=${encodeURIComponent(searchString)}`,
            method: 'get',
        });
    }
};

export default GetAllService;
