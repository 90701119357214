import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import IntegrationService from "../../services/IntegrationService";

export const getAllBanks = createAsyncThunk(
    'get-all/banks',
    async (data, { rejectWithValue }) => {
        try {
            const response = await IntegrationService.getAllBanks();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const checkMonobankAuth = createAsyncThunk(
    'check-auth/monobank',
    async (data, { rejectWithValue }) => {
        try {
            const response = await IntegrationService.checkMonobankAuth();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const getPendingSubscription = createAsyncThunk(
    'get-subscription-pending/accept',
    async (data, { rejectWithValue }) => {
        try {
            const response = await IntegrationService.getPendingSubscription();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const getAuthLinkMonobank = createAsyncThunk(
    'get-auth-link/monobank',
    async (data, { rejectWithValue }) => {
        try {
            const response = await IntegrationService.getAuthLinkMonobank();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const authorizeUserMonobank = createAsyncThunk(
    'authorize/user-monobank',
    async (data, { rejectWithValue }) => {
        try {
            const response = await IntegrationService.authorizeUserMonobank();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const putStatusesSubscription = createAsyncThunk(
    'Statuses/edit-sub',
    async (data, { rejectWithValue }) => {
        try {
            const response = await IntegrationService.putStatusesSubscription(data);
            return response;
        } catch (err) {
            // console.log(err);
            return rejectWithValue(err.response?.data?.message || 'Error edit the subscription');
        }
    }
);

export const deactivateMonobank = createAsyncThunk(
    'Statuses/deactivate-monobank',
    async (_, { rejectWithValue }) => {
        try {
            const response = await IntegrationService.activeMonobank();
            return response.data; // Ensure this is adjusted according to the actual API response structure
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error deactivating Monobank');
        }
    }
);

export const deactivateAndRemoveAllGoogleWorkspace = createAsyncThunk(
    'deactivate-and-remove-all-google-workspace',
    async (_, { rejectWithValue }) => {
        try {
            const response = await IntegrationService.deactivateAndRemoveAllGoogleWorkspace();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error deactivating Monobank');
        }
    }
);

const IntegrationSlice = createSlice({
    name: 'integration',
    initialState: {
        integration: [],
        subscription: [],
        changeStatus: [],
        deactivateMono: false,
        check: false,
        monobank: null,
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getAllBanks.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllBanks.fulfilled, (state, action) => {
                state.loading = false;
                state.integration = action.payload;
            })

            .addCase(getAllBanks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deactivateAndRemoveAllGoogleWorkspace.pending, (state) => {
                state.loading = true;
            })
            .addCase(deactivateAndRemoveAllGoogleWorkspace.fulfilled, (state, action) => {
                state.loading = false;
                state.integration = action.payload;
            })

            .addCase(deactivateAndRemoveAllGoogleWorkspace.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(checkMonobankAuth.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkMonobankAuth.fulfilled, (state, action) => {
                state.loading = false;
                state.check = action.payload;
            })

            .addCase(checkMonobankAuth.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getPendingSubscription.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPendingSubscription.fulfilled, (state, action) => {
                state.loading = false;
                state.subscription = action.payload;
            })

            .addCase(getPendingSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAuthLinkMonobank.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAuthLinkMonobank.fulfilled, (state, action) => {
                state.loading = false;
                state.monobank = action.payload;
            })

            .addCase(getAuthLinkMonobank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(authorizeUserMonobank.pending, (state) => {
                state.loading = true;
            })
            .addCase(authorizeUserMonobank.fulfilled, (state, action) => {
                state.loading = false;
                state.authorize = action.payload;
            })

            .addCase(authorizeUserMonobank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(putStatusesSubscription.pending, (state) => {
                state.loading = true;
            })
            .addCase(putStatusesSubscription.fulfilled, (state, action) => {
                state.loading = false;
                state.changeStatus = action.payload;
            })

            .addCase(putStatusesSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deactivateMonobank.pending, (state) => {
                state.loading = true;
            })
            .addCase(deactivateMonobank.fulfilled, (state, action) => {
                state.loading = false;
                state.deactivateMono = action.payload;
            })

            .addCase(deactivateMonobank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
})

export default IntegrationSlice.reducer;