import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GroupService from "../../services/GroupService";

export const getAllGroups = createAsyncThunk('get-all-groups', async (data, { rejectWithValue }) => {
    try {
        const response = await GroupService.getAllGroups();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const deleteAllExceptGroups = createAsyncThunk('delete-all-except-groups', async (data, { rejectWithValue }) => {
    try {
        const response = await GroupService.deleteAllExceptGroups(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

const GroupSlice = createSlice({
    name: 'groupSlice',
    initialState: {
        groups: [],
        groupsId: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getAllGroups.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllGroups.fulfilled, (state, action) => {
                state.loading = false
                state.groups = action.payload;
            })
            .addCase(getAllGroups.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deleteAllExceptGroups.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteAllExceptGroups.fulfilled, (state, action) => {
                state.loading = false
                state.groupsId = action.payload;
            })
            .addCase(deleteAllExceptGroups.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },

});

export default GroupSlice.reducer;
