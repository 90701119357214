import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import NotificationsService from "../../services/NotificationsService";

export const getRemindUser = createAsyncThunk(
    'get-remind/user',
    async (data, { rejectWithValue }) => {
        try {
            const response = await NotificationsService.getRemindUser();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching remind me');
        }
    }
);

export const getUserSubscriptionRecordNotifications = createAsyncThunk(
    'get-user-subscription-record-notifications',
    async (data, { rejectWithValue }) => {
        try {
            const response = await NotificationsService.getUserSubscriptionRecordNotifications();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching remind me');
        }
    }
);

export const getUserSubscriptionRecordNotificationsMonth = createAsyncThunk(
    'get-user-subscription-record-notifications-month',
    async (month, { rejectWithValue }) => {
        try {
            const response = await NotificationsService.getUserSubscriptionRecordNotificationsMonth(month);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching remind me');
        }
    }
);

const NotificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notification: [],
        notificationSubscriptionRecord: [],
        notificationSubscriptionRecordMonth: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getRemindUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRemindUser.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = action.payload;
            })
            .addCase(getRemindUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getUserSubscriptionRecordNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserSubscriptionRecordNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationSubscriptionRecord = action.payload;
            })
            .addCase(getUserSubscriptionRecordNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getUserSubscriptionRecordNotificationsMonth.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserSubscriptionRecordNotificationsMonth.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationSubscriptionRecordMonth = action.payload;
            })
            .addCase(getUserSubscriptionRecordNotificationsMonth.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
})

export default NotificationSlice.reducer;