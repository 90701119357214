import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AddSubscriptionService from "../../services/AddSubscriptionService";
import ApplicationService from "../../services/ApplicationService";

export const postSubscriptions = createAsyncThunk('customSubscription/add', async (data, { rejectWithValue }) => {
    try {
        const response = await AddSubscriptionService.postSubscriptions(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const postSubscriptionsByServiceId = createAsyncThunk('customSubscriptionByServiceId/add', async (data, { rejectWithValue }) => {
    try {
        const response = await AddSubscriptionService.postSubscriptionsByServiceId(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const postQuickSubscriptions = createAsyncThunk('customQuickSubscription/add', async (data, { rejectWithValue }) => {
    try {
        const response = await AddSubscriptionService.postQuickSubscriptions(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getCurrency = createAsyncThunk('customSubscription/getCurrency', async (data, { rejectWithValue }) => {
    try {
        const response =  await AddSubscriptionService.getCurrency();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getUserCards = createAsyncThunk('getCards/user', async (data, { rejectWithValue }) => {
    try {
        const response =  await AddSubscriptionService.getUserCards();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllBillingType = createAsyncThunk('customSubscription/getBillingType', async (data, { rejectWithValue }) => {
    try {
        const response =  await AddSubscriptionService.getAllBillingType();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});


export const getPaymentMethod = createAsyncThunk('UserCustomPaymentMethod/get-all-payments-methods', async (data, { rejectWithValue }) => {
    try {
        const response =  await AddSubscriptionService.getPaymentMethod();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getBillingCycle = createAsyncThunk('BillingCycle/get-all', async (data, { rejectWithValue }) => {
    try {
        const response =  await AddSubscriptionService.getBillingCycle();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getCategory = createAsyncThunk('Category/get-all-categories-with-sub-categories', async (data, { rejectWithValue }) => {
    try {
        const response =  await AddSubscriptionService.getCategory();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getRemindMe = createAsyncThunk('RemindMe/get-all', async (data, { rejectWithValue }) => {
    try {
        const response =  await AddSubscriptionService.getRemindMe();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getSubscriptionType = createAsyncThunk('SubscriptionType/get-all', async (data, { rejectWithValue }) => {
    try {
        const response =  await AddSubscriptionService.getSubscriptionType();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getService = createAsyncThunk(
    'Service/get',
    async ({ searchString }, { rejectWithValue }) => {
        try {
            const response = await AddSubscriptionService.getService({ service: searchString });
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const putDetailsSubscriptions = createAsyncThunk(
    'CustomSubscriptionDetails/edit',
    async (data, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.putDetailsSubscriptions(data);
            return response;
        } catch (err) {
            // console.log(err);
            return rejectWithValue(err.response?.data?.message || 'Error edit the subscription');
        }
    }
);


const addSubscriptionSlice = createSlice({
    name: 'addSubscription',
    initialState: {
        subscriptions: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(postSubscriptions.pending, (state)=> {
                state.loading = true;
            })
            .addCase(postSubscriptions.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(postSubscriptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(postSubscriptionsByServiceId.pending, (state)=> {
                state.loading = true;
            })
            .addCase(postSubscriptionsByServiceId.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(postSubscriptionsByServiceId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(putDetailsSubscriptions.pending, (state)=> {
                state.loading = true;
            })
            .addCase(putDetailsSubscriptions.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(putDetailsSubscriptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(postQuickSubscriptions.pending, (state)=> {
                state.loading = true;
            })
            .addCase(postQuickSubscriptions.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })
            .addCase(postQuickSubscriptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getCurrency.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getCurrency.fulfilled, (state, action) => {
                state.loading = false
                state.currency = action.payload;
            })
            .addCase(getCurrency.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getUserCards.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getUserCards.fulfilled, (state, action) => {
                state.loading = false
                state.UserCards = action.payload;
            })
            .addCase(getUserCards.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(getAllBillingType.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllBillingType.fulfilled, (state, action) => {
                state.loading = false
                state.billingType = action.payload;
            })
            .addCase(getAllBillingType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getPaymentMethod.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getPaymentMethod.fulfilled, (state, action) => {
                state.loading = false
                state.PaymentMethod = action.payload;
            })
            .addCase(getPaymentMethod.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getBillingCycle.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getBillingCycle.fulfilled, (state, action) => {
                state.loading = false
                state.BillingCycle = action.payload;
            })
            .addCase(getBillingCycle.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getCategory.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.loading = false
                state.category = action.payload;
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getRemindMe.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getRemindMe.fulfilled, (state, action) => {
                state.loading = false
                state.remindMe = action.payload;
            })
            .addCase(getRemindMe.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getSubscriptionType.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getSubscriptionType.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptionType = action.payload;
            })
            .addCase(getSubscriptionType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getService.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getService.fulfilled, (state, action) => {
                state.loading = false
                state.service = action.payload;
            })
            .addCase(getService.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },

});

export default addSubscriptionSlice.reducer;
