import fetch from 'auth/FetchInterceptor'

const DashboardService = {}

DashboardService.GetDefault = function (data) {
    return fetch({
        url: '/Account/login',
        method: 'post',
        data: data
    })
}

DashboardService.getSubscriptionsStatisticDashboardByUserId = function (year) {
    return fetch({
        url: `/Subscription/get-subscriptions-statistic-dashboard-by-user-id?year=${year}`,
        method: 'get'
    })
}

DashboardService.getUpcomingPaymentsSubscriptionRecord = function () {
    return fetch({
        url: '/SubscriptionRecord/upcoming-payment-subscription-record',
        method: 'get'
    })
}

DashboardService.getTopExpensesSubscriptionRecord = function () {
    return fetch({
        url: '/SubscriptionRecord/top-expenses-subscription-record',
        method: 'get'
    })
}

export default DashboardService;