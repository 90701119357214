import fetch from "../auth/FetchInterceptor";


const ProfileService = {}

ProfileService.getAccountProfile = function () {
    return fetch({
        url: '/Account/profile',
        method: 'get'
    })
}

ProfileService.changePassword = function (data) {
    return fetch({
        url: '/Account/changePassword',
        method: 'put',
        data: data
    })
}

ProfileService.editProfile = function (data) {
    return fetch({
        url: '/Account/edit-profile',
        method: 'put',
        data: data
    })
}

ProfileService.getAllCountry = function () {
    return fetch({
        url: '/Country/get-all',
        method: 'get'
    })
}

ProfileService.changeEmail = function (data) {
    return fetch({
        url: '/Account/change-email',
        method: 'put',
        data: data
    })
}

ProfileService.getAllCurrency = function () {
    return fetch({
        url: '/Currency/get-all-currencies',
        method: 'get'
    })
}

ProfileService.getAllDateFormat = function () {
    return fetch({
        url: '/DateFormat/get-all',
        method: 'get'
    })
}

ProfileService.deleteAccount = function () {
    return fetch({
        url: '/Account/delete-account',
        method: 'put'
    })
}

export default ProfileService;