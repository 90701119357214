import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import GetAllService from "../../services/GetAllService";

export const getServices = createAsyncThunk(
    'service/getServices',
    async ({ pageIndex, pageSize, searchString }, { rejectWithValue }) => {
        try {
            // Тепер викликаємо getServices з додатковим аргументом searchString
            const response = await GetAllService.getServices(pageIndex, pageSize, searchString);
            return response.items;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching services');
        }
    }
);


const GetAllServiceSlice = createSlice({
    name: 'service',
    initialState: {
        services: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getServices.pending, (state) => {
                state.loading = true;
            })
            // .addCase(getServices.fulfilled, (state, action) => {
            //     state.loading = false;
            //     // This will now properly merge and deduplicate the services based on `id`.
            //     const servicesMap = new Map([...state.services, ...action.payload].map(service => [service.id, service]));
            //     state.services = Array.from(servicesMap.values());
            // })
            .addCase(getServices.fulfilled, (state, action) => {
                state.loading = false
                state.services = action.payload;
            })
            .addCase(getServices.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default GetAllServiceSlice.reducer
