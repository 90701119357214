import fetch from 'auth/FetchInterceptor';
import FinancialsService from "./FinancialsService";

const ApplicationService = {}

ApplicationService.getSubscriptions = function () {
        return fetch({
            url: '/Subscription/get-subscriptions',
            method: 'get',
        });
};

ApplicationService.getSubscriptionsNotPaid = function () {
    return fetch({
        url:'/Subscription/get-subscriptions-not-paid',
        method: 'get',
    })
}

ApplicationService.deleteSubscriptions = function (data) {
    return fetch({
        url: `/CustomSubscription/delete-by-id?subscriptionId=${data}`,
        method: 'delete'
    });
};

ApplicationService.editSubscriptions = function (data) {
    return fetch({
        url: `/CustomSubscription/edit?id=${data.id}`,
        method: 'post',
        data: data
    })
}

ApplicationService.getAllCategories = function () {
    return fetch({
        url: '/Category/get-all-categories-with-sub-categories',
        method: 'get'
    });
}

ApplicationService.getDetailsSubscription = function (id) {
    return fetch({
        url: `/Subscription/get-details-subscription?subscriptionId=${id}`,
        method: 'get'
    })
}

ApplicationService.getPriceAndDateTransactions = function (data) {
    return fetch({
        url: `/Transaction/get-price-and-date-transactions?subscriptionId=${data}`,
        method: 'get'
    });
};

ApplicationService.ChangeSubscriptionVisibility = function ({subscriptionId, isHidden}) {
    return fetch({
        url: `/Subscription/change-subscription-visibility?subscriptionId=${subscriptionId}&isHidden=${isHidden}`,
        method: 'put'
    })
}

ApplicationService.editDetailsSubscription = function (data) {
    return fetch({
        url: `/Subscription/edit-details-subscription`,
        method: 'put',
        data: data
    })
}

export default ApplicationService;
