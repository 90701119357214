import fetch from "../auth/FetchInterceptor";
import ApplicationService from "./ApplicationService";

const FinancialsService = {}

FinancialsService.getPaymentMethods = function () {
    return fetch({
        url: '/UserCustomPaymentMethod/get-all-payments-methods',
        method: 'get'
    })
}

FinancialsService.getUserBank = function () {
    return fetch({
        url: '/UserBank/get-user-banks',
        method: 'get'
    })
}

FinancialsService.getTransaction = function ({ userBankId }) {
    return fetch({
        url: `/Transaction/get-transactions-by-user-bank?userBankId=${userBankId}&pageIndex=1&pageSize=100`,
        method: 'get'
    })
}

FinancialsService.ChangeTransactionVisibility = function ({transactionId, isHidden}) {
    return fetch({
        url: `/Transaction/change-transaction-visibility?transactionId=${transactionId}&isHidden=${isHidden}`,
        method: 'put'
    })
}

FinancialsService.getTransactionByUserId = function() {
    return fetch ({
        url: '/Transaction/get-disputed-transactions-by-user-id',
        method: 'get'
    })
}

FinancialsService.getAllTransactionUser = function () {
    return fetch ({
        url: '/Transaction/get-all-transactions-by-user-id',
        method: 'get'
    })
}

FinancialsService.getCardsByUserId = function () {
    return fetch({
        url: '/Card/get-cards-user',
        method: 'get'
    })
}

FinancialsService.postCard = function (data) {
    return fetch({
        url: '/Card/create-card',
        method: 'post',
        data: data
    })
}

FinancialsService.deleteCard = function (data) {
    return fetch({
        url: `/Card/delete-card?cardId=${data}`,
        method: 'delete'
    });
};

FinancialsService.editCard = function (data) {
    return fetch({
        url: '/Card/edit-card',
        method: 'put',
        data: data
    })
}

FinancialsService.getSource = function () {
    return fetch({
        url: '/Card/get-source-cards',
        method: 'get'
    })
}

FinancialsService.deleteAllDataByCardId = function (data) {
    return fetch({
        url: `/Card/delete-all-data-by-card-id?cardId=${data}`,
        method: 'delete'
    });
};

export default FinancialsService;