import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GoogleWorkspaceService from "../../services/GoogleWorkspaceService";

export const postGoogleWorkspaceReports = createAsyncThunk(
    'post-reports/google-workspace',
    async ({appAuthToken, googleApiToken, refreshGoogleApiToken}, { rejectWithValue }) => {
        try {
            const response = await GoogleWorkspaceService
                .postGoogleWorkspaceReports({
                    appAuthToken: appAuthToken,
                    googleApiToken: googleApiToken,
                    refreshGoogleApiToken: refreshGoogleApiToken
                });
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const setStatusActivatePendingApprovalSubscription = createAsyncThunk(
    'set-active-to-pendind-approval/statuses-google-workspace',
    async ({subscriptionId, ActiveStatuses}, { rejectWithValue }) => {
        try {
            const response = await GoogleWorkspaceService
                .setStatusActivatePendingApprovalSubscription({
                    subscriptionId: subscriptionId,
                    ActiveStatuses: ActiveStatuses
                });

            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const setSubscriptionToActiveGoogleWorkspace = createAsyncThunk(
    'Statuses-googleWorkspase/edit-sub',
    async (data, { rejectWithValue }) => {
        try {
            const response = await GoogleWorkspaceService.setSubscriptionToActiveGoogleWorkspace(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error edit the subscription');
        }
    }
);


export const getPendingSubscriptionsGoogleWorkspace = createAsyncThunk(
    'get-pending-googleWorkspase/subscription',
    async (data, { rejectWithValue }) => {
        try {
            const response = await GoogleWorkspaceService.getPendingSubscriptionsGoogleWorkspace();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error edit the subscription');
        }
    }
);

export const getPendingApprovalSubscriptionsGoogleWorkspace = createAsyncThunk(
    'get-pending-approval-googleWorkspase/subscriptions',
    async (data, { rejectWithValue }) => {
        try {
            const response = await GoogleWorkspaceService.getPendingApprovalSubscriptionsGoogleWorkspace();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error edit the subscription');
        }
    }
);

export const deactivateGoogleWorkspace = createAsyncThunk(
    'deactivate-googleWorkspase/subscriptions',
    async (data, { rejectWithValue }) => {
        try {
            const response = await GoogleWorkspaceService.deactivateGoogleWorkspace();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error edit the subscription');
        }
    }
);


const googleWorkspaceSice = createSlice({
    name: 'googleWorkspace',
    initialState: {
        googleWorkspace: [],
        subscriptionPendingApprovalGoogleWorkspace: [],
        changeStatus: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(postGoogleWorkspaceReports.pending, (state)=> {
                state.loading = true;
            })
            .addCase(postGoogleWorkspaceReports.fulfilled, (state, action) => {
                state.loading = false
                state.initialState = action.payload;
            })

            .addCase(postGoogleWorkspaceReports.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deactivateGoogleWorkspace.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deactivateGoogleWorkspace.fulfilled, (state, action) => {
                state.loading = false
                state.initialState = action.payload;
            })

            .addCase(deactivateGoogleWorkspace.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(setStatusActivatePendingApprovalSubscription.pending, (state)=> {
                state.loading = true;
            })
            .addCase(setStatusActivatePendingApprovalSubscription.fulfilled, (state, action) => {
                state.loading = false
                state.initialState = action.payload;
            })

            .addCase(setStatusActivatePendingApprovalSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(setSubscriptionToActiveGoogleWorkspace.pending, (state)=> {
                state.loading = true;
            })
            .addCase(setSubscriptionToActiveGoogleWorkspace.fulfilled, (state, action) => {
                state.loading = false
                state.changeStatus = action.payload;
            })

            .addCase(setSubscriptionToActiveGoogleWorkspace.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getPendingSubscriptionsGoogleWorkspace.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getPendingSubscriptionsGoogleWorkspace.fulfilled, (state, action) => {
                state.loading = false
                state.googleWorkspace = action.payload;
            })

            .addCase(getPendingSubscriptionsGoogleWorkspace.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getPendingApprovalSubscriptionsGoogleWorkspace.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getPendingApprovalSubscriptionsGoogleWorkspace.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptionPendingApprovalGoogleWorkspace = action.payload;
            })

            .addCase(getPendingApprovalSubscriptionsGoogleWorkspace.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default googleWorkspaceSice.reducer
